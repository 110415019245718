import store from 'store/index'
import Pages from 'pages'
import MenuGuest from 'layouts/frontend/components/MenuGuest'
// import MenuPartner from 'layouts/frontend/components/MenuPartner'

export default [
  {
    name: 'Login',
    path: '/login',
    redirect: {
      name: 'MemberSignin'
    }
  },
  {
    name: 'MemberSignin',
    path: '/member/signin',
    meta: {
      title: '會員登入',
    },
    components: {
      default: Pages.MemberLogin,
      sideMenu: MenuGuest,
    },
    beforeEnter: (to, from, next) => {
      if (store.getters['member/profile']) {
        next({ name: 'Partner' })
      } else {
        next()
      }
    }
  },
  {
    name: 'MemberSignup',
    path: '/member/signup',
    meta: {
      title: '銷售夥伴註冊',
    },
    components: {
      default: Pages.MemberSignup,
      sideMenu: MenuGuest,
    },
  },
  {
    name: 'MemberResend',
    path: '/member/resend',
    meta: {
      title: '重新發送驗證碼',
    },
    components: {
      default: Pages.MemberResend,
      sideMenu: MenuGuest,
    },
  },
  {
    name: 'MemberConfirm',
    path: '/member/confirm',
    meta: {
      title: '帳號驗證',
    },
    components: {
      default: Pages.MemberConfirm,
      sideMenu: MenuGuest,
    },
  },
  {
    name: 'MemberPasswd',
    path: '/member/passwd',
    meta: {
      title: '變更密碼',
    },
    components: {
      default: Pages.MemberPasswd,
      sideMenu: MenuGuest,
    },
  },
  {
    name: 'ForgetPasswd',
    path: '/member/forget/:username?',
    meta: {
      title: '忘記密碼',
    },
    components: {
      default: Pages.MemberForgetPasswd,
      sideMenu: MenuGuest,
    },
  },
  {
    name: 'ModifyInfo',
    path: '/member/modify',
    meta: {
      title: '變更資料',
    },
    components: {
      default: Pages.MemberModifyInfo,
      sideMenu: MenuGuest,
    },
  },
]
