<template>
  <div class="frame__header">
    <div class="main-bar">
      <div class="icon-button small menu-controller" ref="menuBtn" @click="toggleMenu">
        <i v-if="showMenu" class="icon-close" />
        <i v-else class="icon-hamburger-menu" />
      </div>
      <router-link :to="{name: 'Partner'}" class="header-logo">
        <img src="~images/logo-white-m.png" alt="大拇哥">
      </router-link>
      <div class="header-member">
        <MemberEntry ref="memberEntry" mobile @clickAvatar="toggleCard" />
      </div>
    </div>
    <div class="member-wrap">
      <MemberCard v-if="showMemberCard" @closePanel="closeMemberCard" />
    </div>
    <div class="mobile-menu" :class="{active: showMenu}">
      <router-view name="sideMenu" @closeMenu="closeMenu" />
      <div v-if="$member && $member.isAdmin" class="admin-box">
        <a href="/admin" class="ui button minor">
          站方管理後台
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import MemberEntry from './MemberEntry'
import MemberCard from './MemberInfo'

export default {
  components: {
    MemberEntry,
    MemberCard,
  },
  data() {
    return {
      showMenu: false,
      showMemberCard: false,
    }
  },
  watch: {
    '$route.path'() {
      this.showMemberCard = false
      this.showMenu = false
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    toggleCard() {
      this.showMemberCard = !this.showMemberCard
    },
    closeMemberCard(clickTarget) {
      const isController = clickTarget === this.$refs.memberEntry.$refs.avatarBtn
      if (this.showMemberCard && !isController) {
        this.showMemberCard = false
        this.$refs.memberEntry.active = false
      }
    },
    closeMenu(clickTarget) {
      const isController = jQuery.contains(this.$refs.menuBtn, clickTarget)
      if (this.showMenu && !isController) this.showMenu = false
    }
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.frame__header
  position fixed
  width 100%
  line-height 1
  z-index 200
.main-bar
  display flex
  background $ugBlue1
  padding .75rem 3rem
  box-shadow 0px 2px 16px rgba(170, 170, 170, 0.1)
  color #fff
  +under(md)
    padding .75rem 1rem
  .menu-controller
    margin-right 1rem
  .header-logo
    display block
    height 28px
    img
      height 100%
  .header-member
    position absolute
    right 42px
    top 50%
    transform translateY(-50%)
    +under(md)
      right 14px
.mobile-menu
  position absolute
  top 100%
  left -100%
  width 100%
  padding 2rem 3rem
  background-color $ugBlue1
  transition .5s ease-in-out
  &.active
    left 0
  +under(sm)
    padding 2rem 1.5rem
    height calc(100vh - 49px)
    background-image url('~images/img-sidebar.png')
    background-size contain
    background-position bottom center
    background-repeat no-repeat
.member-wrap
  position absolute
  top 100%
  right 0
  +under(sm)
    position fixed
    top 49px
    width 100%
.admin-box
  margin-top 2rem
  +under(sm)
    > a
      width 100%
</style>
