<template>
  <div class="menu">
    <router-link :to="{name: 'Partner'}" class="special-item">
      <span>銷售夥伴專區</span>
    </router-link>

    <router-link :to="{name: 'Saleskit'}" class="menu-item">
      <i class="icon-compare menu-icon" />
      <span class="menu-topic">銷售工具</span>
    </router-link>
    <router-link :to="{name: 'Perspectives'}" class="menu-item">
      <i class="icon-eye-open menu-icon" />
      <span class="menu-topic">經理人觀點</span>
    </router-link>
    <router-link :to="{name: 'Training'}" class="menu-item">
      <i class="icon-reading-time menu-icon" />
      <span class="menu-topic">夥伴學習課程</span>
    </router-link>
    <router-link :to="{name: 'Calendar'}" class="menu-item">
      <i class="icon-calendar menu-icon" />
      <span class="menu-topic">交易日曆</span>
    </router-link>
    <router-link :to="{name: 'Bulletin'}" class="menu-item">
      <i class="icon-speaker menu-icon" />
      <span class="menu-topic">重要公告</span>
    </router-link>
    <router-link :to="{name: 'Forms'}" class="menu-item">
      <i class="icon-file menu-icon" />
      <span class="menu-topic">交易表單</span>
    </router-link>
  </div>
</template>

<script>
export default {
  mounted() {
    document.addEventListener('click', this.detectClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.detectClickOutside)
  },
  methods: {
    detectClickOutside(event) {
      const isClickOutside = !jQuery.contains(this.$el, event.target)
      if (isClickOutside) this.$emit('closeMenu', event.target)
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~layouts/frontend/components/menu.styl'
</style>
