
import VueRouter from 'vue-router'
import store from 'store/index'
import Pages from 'pages'
import MenuGuest from 'layouts/frontend/components/MenuGuest'
import MenuPartner from 'layouts/frontend/components/MenuPartner'

const router = new VueRouter({
  base: '/app/',
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/partner',
    },
    ...(require('./members.js').default),
    { // 基金頁面
      name: 'FundShow',
      path: '/fund/:id',
      meta: { requiresAuth: true },
      components: {
        default: Pages.FundShow,
        sideMenu: MenuPartner,
      },
    },
    { // 基金頁面
      name: 'Compare',
      path: '/compare/:ids/:dateStart?/:dateEnd?',
      meta: { requiresAuth: true },
      components: {
        default: Pages.Compare,
        sideMenu: MenuPartner,
      },
    },
    { // 關於UG
      name: 'About',
      path: '/about',
      meta: {
        title: '關於 UG',
      },
      components: {
        default: Pages.About,
        sideMenu: MenuGuest,
      },
    },
    { // 銷售夥伴專區
      name: 'Partner',
      path: '/partner',
      meta: {
        requiresAuth: true,
        title: '銷售夥伴專區',
      },
      components: {
        default: Pages.Partner,
        sideMenu: MenuPartner,
      },
    },
    { // 銷售工具
      name: 'Saleskit',
      path: '/saleskit',
      meta: { requiresAuth: true },
      redirect: { name: 'SaleskitCharts' },
      components: {
        default: Pages.Saleskit,
        sideMenu: MenuPartner,
      },
      children: [
        {
          name: 'SaleskitCharts',
          path: 'charts/category/:category_id?',
          alias: 'charts',
          component: Pages.SaleskitCharts,
          meta: {
            title: '銷售工具',
          },
        },
        {
          name: 'SaleskitReports',
          path: 'reports',
          component: Pages.SaleskitReports,
          meta: {
            title: '銷售工具',
          },
        },
      ],
    },
    { // 重點圖卡單頁
      name: 'SaleskitChartsContent',
      path: '/saleskit/charts/:id',
      components: {
        default: Pages.SaleskitChartsContent,
        sideMenu: MenuPartner,
      },
    },
    { // 經理人觀點
      name: 'Perspectives',
      path: '/perspectives',
      redirect: { name: 'PerspectiveCategory' },
      components: {
        default: Pages.Perspectives,
        sideMenu: MenuPartner,
      },
      children: [
        {
          name: 'PerspectiveCategory',
          path: 'category/:category?',
          alias: [''],
          meta: {
            requiresAuth: true,
            title: '經理人觀點',
          },
        },
        {
          name: 'PerspectiveShow',
          path: ':id?',
        },
      ]
    },
    { // 夥伴學習課程
      name: 'Training',
      path: '/training',
      redirect: { name: 'TrainingCategory' },
      components: {
        default: Pages.Training,
        sideMenu: MenuPartner,
      },
      children: [
        {
          name: 'TrainingCategory',
          path: 'category/:category?',
          alias: [''],
          meta: {
            requiresAuth: true,
            title: '夥伴學習課程',
          },
        },
        {
          name: 'TrainingShow',
          path: ':id?',
        },
      ]
    },
    { // 交易日曆
      name: 'Calendar',
      path: '/calendar',
      meta: {
        requiresAuth: true,
        title: '交易日曆',
      },
      components: {
        default: Pages.Calendar,
        sideMenu: MenuPartner,
      },
    },
    { // 重要公告單頁
      name: 'BulletinContent',
      path: '/bulletin/:id',
      meta: { requiresAuth: true },
      components: {
        default: Pages.BulletinContent,
        sideMenu: MenuPartner,
      },
    },
    { // 重要公告
      name: 'Bulletin',
      path: '/bulletin',
      meta: {
        requiresAuth: true,
        title: '重要公告',
      },
      components: {
        default: Pages.Bulletin,
        sideMenu: MenuPartner,
      },
    },
    { // 交易表單單頁
      name: 'FormsContent',
      path: '/forms/doc/:id',
      meta: { requiresAuth: true },
      components: {
        default: Pages.FormsContent,
        sideMenu: MenuPartner,
      },
    },
    { // 交易表單
      name: 'Forms',
      path: '/forms/:type_id?',
      meta: { requiresAuth: true },
      components: {
        default: Pages.Forms,
        sideMenu: MenuPartner,
      },
    },
    { // 條款頁面
      name: 'Terms',
      path: '/terms/:id',
      components: {
        default: Pages.Terms,
        sideMenu: MenuGuest,
      },
    },
    { // 聯繫我們
      name: 'Contact',
      path: '/contact',
      components: {
        default: Pages.Contact,
        sideMenu: MenuGuest,
      },
      meta: {
        title: '聯繫我們'
      },
    },
    // 404 and System Pages
    {
      name: 'NotFound',
      path: '/404',
      components: {
        default: Pages['404'],
        sideMenu: MenuGuest,
      },
    },
    {
      path: '/:catchAll(.*)',
      components: {
        default: Pages['404'],
        sideMenu: MenuGuest,
      },
    },
    // { // 市場觀點
    //   name: 'Insights',
    //   path: '/insights',
    //   redirect: '/insights/category/:category_id?',
    //   components: {
    //     default: Pages.ArticlesIndex,
    //     sideMenu: MenuGuest,
    //   },
    //   children: [
    //     {
    //       name: 'InsightsIndex',
    //       path: 'category/:category_id?',
    //       component: Pages.ArticlesInsights,
    //       meta: {
    //         title: '市場觀點',
    //       },
    //     },
    //     { // 文章單頁
    //       name: 'InsightsArticle',
    //       path: 'article/:id',
    //       component: Pages.ArticlesShow,
    //     },
    //   ]
    // },
    // { // 關鍵圖卡
    //   name: 'FlashCard',
    //   path: '/flashcard',
    //   redirect: '/flashcard/category/:category_id?',
    //   components: {
    //     default: Pages.ArticlesIndex,
    //     sideMenu: MenuGuest,
    //   },
    //   children: [
    //     {
    //       name: 'FlashCardIndex',
    //       path: 'category/:category_id?',
    //       component: Pages.ArticlesFlashCard,
    //       meta: {
    //         title: '關鍵圖卡',
    //       },
    //     },
    //     { // 文章單頁
    //       name: 'FlashCardArticle',
    //       path: 'article/:id',
    //       component: Pages.ArticlesShow,
    //     },
    //   ]
    // },
  ],
  linkExactActiveClass: 'is-active',
  scrollBehavior(to, from, savedPosition) {
    // 如果是相同的畫面元件，則不改變捲軸狀態
    if (from.matched[0]?.components.default === to.matched[0].components.default) {
      return
    }
    return new Promise((resolve, reject) => {
      // 不同主題頁面才需要捲到最上方
      if ((from.name !== to.name) || (from.params.id !== to.params.id)) {
        setTimeout(() => {
          if (savedPosition) {
            resolve(savedPosition)
          } else {
            resolve({ x: 0, y: 0 })
          }
        }, 500)
      }
    })
  },
})

store.watch(state => state.member.profile, (nowLogin, wasLogin) => {
  // 只影響背景頁面
  if (document.hasFocus()) {
    return
  }
  // 若登出則到登入頁
  if (!nowLogin && router.currentRoute.meta.requiresAuth) {
    router.replace('/login').catch(() => {})
  }
  // 若登入，則到夥伴頁
  if (nowLogin && router.currentRoute.path.match(/^\/member\//)) {
    router.replace('/partner').catch(() => {})
  }
})

router.beforeEach((to, from, next) => {
  const memberProfile = store.getters['member/profile']
  const currentRequireAuth = to.matched.some(record => record.meta.requiresAuth)
  if (currentRequireAuth && !memberProfile) {
    // 需要登入的頁面
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else if (currentRequireAuth && store.getters['member/requireConfirmation'] && to.name !== 'MemberConfirm') {
    // 帳號尚未驗證
    next({ name: 'MemberConfirm' })
  } else {
    next()
  }
})

// Page View
router.afterEach(function(to) {
  // 嘗試設定頁面標題
  setTimeout(() => {
    if (typeof router.app.$setTitle === 'function') {
      router.app.$setTitle(to.meta.title, 0)
    }
  }, 50)
  // https://doc.vux.li/zh-CN/development/vue-google-analytics.html
  if (typeof window.ga == 'function') {
    window.ga('set', 'page', to.fullPath)
    window.ga('send', 'pageview')
  }
})

export default router
