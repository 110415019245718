import Vue from 'lib/vue/essential'
import Layout from 'layouts/frontend/index.vue'

import router from 'routes'
import store from 'store'
import 'tarobo-ui/fontello'
import 'modules/ui/styleguide.styl'

document.addEventListener('DOMContentLoaded', async () => {
  // 嘗試登入
  if (store.getters['member/authToken']) {
    await store.dispatch('member/loadProfile').catch(() => {
      alert({
        message: '連線發生問題，請稍後再試'
      })
    })
  }
  const app = new Vue({
    router,
    store,
    render: h => h(Layout),
  }).$mount()
  document.body.appendChild(app.$el)
})
