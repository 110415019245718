<template>
  <div class="frame__side">
    <div class="logo__section">
      <router-link :to="{name: 'Partner'}" class="logo">
        <img src="~images/logo-white.svg" alt="大拇哥">
      </router-link>
    </div>
    <Scroll class="side-menu">
      <router-view name="sideMenu" />
    </Scroll>
    <div class="member-entry">
      <MemberEntry ref="memberEntry" @clickAvatar="toggleCard" />
    </div>
    <div class="avatar-panel">
      <MemberCard v-if="showMemberCard" @closePanel="closeMemberCard" />
    </div>
  </div>
</template>

<script>
import MemberEntry from './MemberEntry'
import MemberCard from './MemberInfo'
import Scroll from 'modules/ui/scroll'

export default {
  components: {
    MemberEntry,
    MemberCard,
    Scroll,
  },
  data() {
    return {
      showMemberCard: false,
    }
  },
  watch: {
    '$route.path'() {
      this.showMemberCard = false
    },
  },
  methods: {
    toggleCard() {
      this.showMemberCard = !this.showMemberCard
    },
    closeMemberCard(clickTarget) {
      const isController = clickTarget === this.$refs.memberEntry.$refs.avatarBtn
      if (this.showMemberCard && !isController) this.showMemberCard = false
    },
  }
}
</script>

<style lang="stylus" scoped>
.frame__side
  position relative
  flex-shrink 0
  display flex
  flex-direction column
  width 238px
  background-image url('~images/img-sidebar.png')
  background-size contain
  background-position bottom center
  background-repeat no-repeat

  // 選單改用 sticky
  top 0
  left 0
  bottom 0
  position sticky
  height 100vh
  z-index 200
  background-color #08548e

  // 拼接的圓角
  &:before, &:after
    content ""
    position absolute
    height 43px
    width 42px
    right -42px
  &:before
    top -1px
    background-image url('~images/side-border-up.png')
  &:after
    bottom -1px
    background-image url('~images/side-border-down.png')

.logo__section
  flex-shrink 0
  margin 2rem 0 4rem
  text-align center
  .logo
    display block
    width 116px
    margin auto
    img
      width 100%
.side-menu
  flex 1 1 auto
  margin 0 1.5rem
.member-entry
  flex-shrink 0
  /deep/ .member
    margin 4rem auto 7rem
.avatar-panel
  position absolute
  bottom 0
  left 100%
  z-index 10

// IE 不支援 sticky
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
  .frame__side
    position fixed
  /deep/ + .frame__container
      margin-left 238px
</style>
