<template>
  <div class="member">
    <!-- 已登入 -->
    <template v-if="$member">
      <div ref="avatarBtn"
           class="avatar-img"
           :class="classes"
           @click="clickAvatar"
      />
      <template v-if="!mobile">
        <div class="avatar-name">
          {{ $member.name }}，您好
        </div>
        <div v-if="$member && $member.isAdmin" class="btn-wrap" style="margin-top:21px;">
          <a href="/admin" class="ui button minor full">
            站方管理後台
          </a>
        </div>
      </template>
    </template>

    <!-- 未登入 -->
    <template v-else>
      <div class="btn-wrap signin">
        <router-link class="ui button major round full"
                     :class="{small: mobile}"
                     :to="{name: 'Login'}"
        >
          <span v-if="!mobile">會員</span>登入
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    mobile: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      active: false,
    }
  },
  computed: {
    classes() {
      return [
        this.mobile ? 'small' : 'normal',
        this.active ? 'active' : '',
      ]
    },
  },
  methods: {
    clickAvatar() {
      this.active = !this.active
      this.$emit('clickAvatar')
    },
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.member
  text-align center
  .avatar-img
    position relative
    margin auto
    background-image url('~images/avatar-blue.svg')
    background-size contain
    background-position center center
    background-repeat no-repeat
    cursor pointer
    &.active:before, &:hover:before
      content ''
      position absolute
      top -2%
      left -0.5%
      width 102%
      height 102%
      border 1px solid #FF895A
      border-radius 50%
    &.normal
      width 42px
      height 42px
    &.small
      width 28px
      height 28px
  .avatar-name
    margin-top 1rem
    color #DBEDFA
    font-weight bold
  .btn-wrap
    +over(xl)
      width 140px
      margin auto
    &.signin .ui.button.major
      color $ugBlue1
      background-color #fff
      &:hover, &:focus
        color white
        background #f08054
      &:active
        color white
        background #e0784f

</style>
