<template>
  <div v-cloak id="page-app-launcher" class="main">
    <div class="frame__layout">
      <LayoutSide v-if="$deviceView.wider" />
      <MobileHeader v-else />
      <div class="frame__container">
        <router-view />
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import LayoutSide from './components/LayoutSide'
import MobileHeader from './components/MobileHeader'
import Footer from './components/Footer'

import './scaffolding.styl'
import './font.styl'
import './common.styl'

export default {
  components: {
    LayoutSide,
    MobileHeader,
    Footer,
  },
}
</script>

<style lang="stylus" scoped>
@require '~modules/ui/common.styl'
.main
  height 100%
.frame__layout
  width 100%
  +over(xl)
    display flex
    background $ugBlue1
.frame__container
  display flex
  flex-direction column
  justify-content space-between
  background #F6F6F6
  overflow hidden
  +over(xl)
    min-height 100vh
    flex 1 1 auto
    box-shadow -6px 1px 6px rgba(170, 170, 170, 0.2)
  +under(xl)
    min-height calc(100vh - 49px)
    padding-top 49px
</style>

<style lang="stylus">
.profiler-results.profiler-top.profiler-left
  display none
</style>
